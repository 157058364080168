import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import * as d3 from "d3";
import D3Version from "./D3Version";
import Chart from "../../components/d3/Chart";
import data from "../../components/d3/data";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`This is part 2 of a 3-part series about using Google Maps and D3 to create an elevation graph that responds dynamically with user interaction. Here are all 3 posts:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 1: Google Maps`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-16-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 2: D3 for Data Visualization`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-03-27-draw-an-interactive-elevation-chart-with-d3-and-react-pt-3"
          }}>{`Part 3: Creating User Interaction`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}>{`Drawing the Chart`}</MDXTag>
      <MDXTag name="p" components={components}>{`In this part (part 2), we’ll be building an interactive, dynamic area chart that plots distance on the x-axis and elevation on the y-axis.`}</MDXTag>
      <MDXTag name="h4" components={components}><MDXTag name="a" components={components} parentName="h4" props={{
          "href": "/posts/2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react"
        }}>{`Catch up on part 1 here!`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`First let’s remind ourselves what we’re building:`}</MDXTag>
      <iframe className="youtube-video" width="720" height="480" src="https://www.youtube.com/embed/9HXlmXwyuKk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      <MDXTag name="p" components={components}>{`We’ve got 5 things to do, plus 1 bonus step to spruce up your chart’s appearance with gridlines that span across the x- and y-scales at each tick on the chart.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`5 Steps + 1 Bonus`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Declare our constants, helper functions, and configure D3 so it’s ready for action.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Map out our "ElevationGraph" component, including:`}</MDXTag>
          <ul style={{
            marginLeft: "3rem"
          }}>
  <li>– what lives on state & what comes on props?</li>
  <li>– decide which lifecycle methods we’ll need</li>
  <li>– name and pseudocode our custom methods</li>
          </ul>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Draw the chart using D3’s API! This is the fun part, and the meat and potatoes of our task today`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Render that bad boy! We’ll talk styles here too, especially regarding svg styling (wtf is viewPort vs. viewBox, etc.)`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Write a callback function that draws a DOM element on the map depending on where the user is hovering`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`[Bonus]`}{` Make it pretty`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`1. Declarations & Config`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Helper Functions`}</MDXTag>
      <MDXTag name="p" components={components}>{`First we need some helper functions. This first batch is pure JavaScript, and do not require the D3 library yet (although `}<MDXTag name="inlineCode" components={components} parentName="p">{`fromLatLngToPoint`}</MDXTag>{` does expect a Google Map instance as a parameter):`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`export const numOfSamples = 100
export const metersToMiles = m => m * 0.000621371
export const metersToFeet = m => m * 3.28084

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false
  for (let i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false
  }
  return true
}

export const fromLatLngToPoint = (latLng, map) => {
  const topRight = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getNorthEast())
  const bottomLeft = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getSouthWest())
  const scale = Math.pow(2, map.getZoom())
  const worldPoint = map.getProjection().fromLatLngToPoint(latLng)
  const point = new window.google.maps.Point(
    (worldPoint.x - bottomLeft.x) * scale,
    (worldPoint.y - topRight.y) * scale
  )
  return point
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`We export these because ideally we’re putting them in a separate `}<MDXTag name="inlineCode" components={components} parentName="p">{`utils.js`}</MDXTag>{` file, to be imported as needed in our `}<MDXTag name="inlineCode" components={components} parentName="p">{`ElevationGraph`}</MDXTag>{` component.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The first, `}<MDXTag name="inlineCode" components={components} parentName="p">{`numOfSamples`}</MDXTag>{`, is a constant; we will be using the number of samples in a few places, so it’s easier to change later because we declare it in a single place. We choose 100 samples along our pathline because it makes the math easy. For example, if our area chart is 600 pixels wide, then we need to divide it up into 6px sections and create a hover event over each to dynamically render a point or “blip” on the map to show the user exactly where on the path she would encounter the elevation and grade our Infobox shows her.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The next two are functions we will use to do unit conversion. The Google Maps Elevation and Distance APIs return us meters, but since we do things backwards in the States, we need to convert those to miles and feet so our user doesn’t have to do conversions herself.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`arraysEqual`}</MDXTag>{` returns true if 2 arrays contain the same elements and false if they don’t.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We will come back to `}<MDXTag name="inlineCode" components={components} parentName="p">{`fromLatLngToPoint`}</MDXTag>{` toward the end of the article — in a nutshell, it takes a `}<MDXTag name="inlineCode" components={components} parentName="p">{`LatLng`}</MDXTag>{` object and a Google Map instance and does a lookup to see where a particular coordinate exists on the user’s screen, so we know where to draw the blip.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`D3 Config`}</MDXTag>
      <MDXTag name="p" components={components}>{`It’s finally time to start working with D3! First let’s make sure we installed and imported it correctly:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`import * as d3 from "d3"

const D3Version = () => (
  <p id="d3-version">
    D3 Version: <strong>{d3.version}</strong>
  </p>
)

<D3Version />
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`That renders:`}</MDXTag>
      <br />
      <D3Version />
      <MDXTag name="p" components={components}>{`Cool! Let’s start configuring:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`// 1. DECLARATIONS / CONFIG
const margin = { top: 0, right: 0, bottom: 15, left: 50 }
const width = 750 - margin.left - margin.right
const height = 155 - margin.top - margin.bottom
const xAxisTicks = 8
const yAxisTicks = 6
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`This is the conventional way of creating margins around your chart in D3.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Next we need to create our xScale and yScale. We will use a linear scale for both.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`For continuous quantitative data, you typically want a linear scale.`}</MDXTag>
        <MDXTag name="p" components={components} parentName="blockquote"><MDXTag name="a" components={components} parentName="p" props={{
            "href": "https://github.com/d3/d3-scale#linear-scales"
          }}>{`- D3 Docs`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`We need to give our linear scale a domain and a range:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`const xScale = d3
  .scaleLinear()
  .domain(d3.extent(data, d => d.x))
  .range([0, width])

const yScale = d3
  .scaleLinear()
  .domain([d3.min(data, co => co.y), d3.max(data, co => co.y)])
  .range([height, 0])
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`d3.scaleLinear`}</MDXTag>{` is a function that creates a scale based on the domain and range you pass it.`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="inlineCode" components={components} parentName="li">{`domain`}</MDXTag>{` takes an array of 2 values that represent the maximum and minimum values in our data set.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`To avoid having to hardcode this, we can use `}<MDXTag name="inlineCode" components={components} parentName="p">{`d3.min`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`d3.max`}</MDXTag>{`, passing in our entire data set and a function that tells D3 how to resolve our data objects to the values we care about.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In this case, because the x-axis only cares about `}<MDXTag name="inlineCode" components={components} parentName="p">{`data.x`}</MDXTag>{` and our y-axis only cares about `}<MDXTag name="inlineCode" components={components} parentName="p">{`data.y`}</MDXTag>{`, mapping out the domain is really simple:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`d3.scaleLinear().domain([d3.min(data, co => co.y), d3.max(data, co => co.y)])
`}</MDXTag></MDXTag>
      <MDXTag name="ol" components={components} props={{
        "start": 2
      }}>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="inlineCode" components={components} parentName="li">{`range`}</MDXTag>{` takes an array of 2 values that represent the boundaries of our svg chart as they will be drawn on the screen.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Cool! Let’s draw the chart. We won’t be plotting the data yet, but it should render at the size we expect based on the data we pass in.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Drawing the SVG`}</MDXTag>
      <MDXTag name="p" components={components}>{`How do we actually draw the chart?`}</MDXTag>
      <MDXTag name="p" components={components}>{`We're going to use D3 the select a DOM node, append an svg element, give it some attributes, and append elements to it (`}<MDXTag name="inlineCode" components={components} parentName="p">{`path`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`g`}</MDXTag>{` elements, specifically).`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`const svg = d3
  .select("#elevationChart")
  .append("svg")
  .attr("width", 750)
  .attr("height", 155)
  .attr("viewBox", "0 0 " + width + " " + 160)
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`D3 is nice to read. Even without knowing the library, we can follow what is going on. D3 selectors and DOM mutations are so much easier to work with than vanilla JavaScript!`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Aside:`}</MDXTag>{` It’s almost `}<MDXTag name="em" components={components} parentName="p">{`too`}</MDXTag>{` easy with D3! We should be mindful of this, and only be mutating our chart, and delegate any other stateful effects to React to be handled more responsibly.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s call `}<MDXTag name="inlineCode" components={components} parentName="p">{`drawChart`}</MDXTag>{` when the component has mounted, and voilà!`}</MDXTag>
      <Chart targetNode={"#chart1"} data={data.fakeData1} transform="translate(47.6, 7.5)" background="rgb(242, 246, 233)" lineColor="51, 51, 51">
  <div id="chart1" />
      </Chart>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Look, we did a thing!`}</MDXTag>{` We’re like, `}<MDXTag name="em" components={components} parentName="p">{`artists`}</MDXTag>{` or something.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here’s what the code looks like right now. This is just everything we’ve done thus far, put together:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`import React from "react"
import * as d3 from "d3"
import { data } from "./data" // This would be an API call IRL!
const margin = { top: 0, right: 0, bottom: 15, left: 50 }
const width = 700 - margin.left - margin.right
const height = 155 - margin.top - margin.bottom

export default class Chart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null
    }
  }

  componentDidMount() {
    this.setState({ data }, () => this.drawChart())
  }

  drawChart = () => {
    const { data } = this.state
    const xScale = d3
      .scaleLinear()
      .domain(d3.extent(data, d => d.x))
      .range([0, width])
    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data, co => co.y), d3.max(data, co => co.y)])
      .range([height, 0])
    const svg = d3
      .select("#elevationChart")
      .append("svg")
      .attr("width", 700)
      .attr("height", 155)
      .attr("viewBox", "0 0 " + width + " " + 160)
      .attr("preserveAspectRatio", "xMinYMid")
      .append("g")
      .attr("transform", \`translate(\${margin.left}, 2.5)\`)

    svg
      .append("g")
      .attr("transform", \`translate(0, \${height})\`)
      .call(d3.axisBottom(xScale))

    svg.append("g").call(d3.axisLeft(yScale))
  }

  render() {
    return (
      <div style={{
        display: "flex",
        justify-content: "center",
        box-shadow: "rgba(0, 0, 0, 0.1) 0px 0px 0.625rem 0px"
      }}>
        {this.state.data.length > 0 && <div id="elevationChart" />}
      </div>
    )
  }
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`This is all pretty simple and stateful. We draw a chart by mutating the DOM and pass it our data. But how do we know what our x- and y-domains are without labels?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Before we get into labelling the axes, let’s draw something first!!`}</MDXTag>
      <MDXTag name="p" components={components}>{`What if we gave our chart some data? Let’s pass it 3 points:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`const data = [
  { x: 5, y: 15 },
  { x: 15, y: 20 },
  { x: 35, y: 5 }
]
<Chart data={data} />
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Remember, the domains of our `}<MDXTag name="inlineCode" components={components} parentName="p">{`xScale`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`yScale`}</MDXTag>{` are created by functions that are calculating the bounds of our chart.`}</MDXTag>
      <MDXTag name="p" components={components}>{`There’s nothing magical about `}<MDXTag name="inlineCode" components={components} parentName="p">{`x`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`y`}</MDXTag>{` here, it’s just where I told the D3 functions to look:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`xScale.domain(d3.extent(data, d => d.x))

yScale.domain([d3.min(data, co => co.y), d3.max(data, co => co.y)])
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Before our chart will render, we need to tell D3 1.) what kind of visualization we want to draw, and 2.) where`}</MDXTag>
      <MDXTag name="p" components={components}>{`In this case, we decided to draw an area chart — basically a line chart with shading underneath.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here’s all we have to do!`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`Configure our visualization & pass D3 our scales, so it knows where on the screen to plot each point:`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Append a path element to our SVG with a `}<MDXTag name="inlineCode" components={components} parentName="li">{`d`}</MDXTag>{` attribute containing our data, along with how we want things to render:`}</MDXTag>
      </MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`// 1.)
const area = d3
  .area()
  .x(d => xScale(d.x))
  .y0(yScale(yScale.domain()[0]))
  .y1(d => yScale(d.y))

// 2.)
svg
  .append("path")
  .attr("d", area(data))
  .attr("class", "chartLine")
  .style("stroke", "#787979")
  .style("stroke-opacity", 0.2)
  .style("stroke-width", 1)
  .style("fill", "#787979")
  .style("fill-opacity", 0.2)
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Input:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{ x: 5, y: 15 },
{ x: 15, y: 20 },
{ x: 35, y: 5 }
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Let’s try it out:`}</MDXTag>
      {
        /* prettier-ignore */
      }
      <Chart targetNode={"#chart2"} data={data.fakeData2} transform="translate(47.5, 5)" lineColor="29, 29, 29" fillColor="102, 173, 204" background="rgb(239, 245, 250)"><div id="chart2" />
      </Chart>
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Whoaaa! Who said we wanted labels?`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`D3 automatically created ticks for our axes. We’ll adjust these later, but for now we get those for free.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s feed it more data. Can we do something to soften those points a bit?`}</MDXTag>
      <MDXTag name="p" components={components}>{`We can use D3’s `}<MDXTag name="inlineCode" components={components} parentName="p">{`curve`}</MDXTag>{` method across our y points and pass it a function name that none of us will ever remember, `}<MDXTag name="inlineCode" components={components} parentName="p">{`curveCatmullRom`}</MDXTag>{`:`}</MDXTag>
      <MDXTag name="p" components={components}>{`While we’re there, let’s customize our ticks and add a grid for better visualization:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`d3.area()
  .x(d => /* ... */)
  .y0(yScale( /* ... */)
  .y1(d => /* ... */)
  .curve(d3.curveCatmullRom.alpha(0.005))

// Let’s write functions that handle spacing our ticks out:
const xAxisTicks = 8
const yAxisTicks = 6
const makeXGridlines = xScale => d3.axisBottom(xScale).ticks(xAxisTicks)
const makeYGridlines = yScale => d3.axisLeft(yScale).ticks(yAxisTicks)

d3.axisBottom(xScale)
  // ...
  .ticks(yAxisTicks)
  .tickSize(0)
  .tickPadding(9)

d3.axisLeft(yScale)
  // ...
  .tickSize(0)
  .tickPadding(8)
  .ticks(yAxisTicks)
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Cool! Let’s see how it looks.`}</MDXTag>
      <Chart targetNode={"#chart3"} data={data.fakeData3} transform="translate(47.5, 5)" ticks={true} lineColor="87, 64, 67" fillColor="146, 105, 111" background="rgb(217, 223, 234)">
  <div id="chart3" />
      </Chart>
      <MDXTag name="p" components={components}>{`Looking pretty good. What about those gridlines?`}</MDXTag>
      <MDXTag name="p" components={components}>{`If we’re feeling clever, we can just make another set of ticks that scale along the same x- and y-scales, only `}<MDXTag name="em" components={components} parentName="p">{`these will stretch the entire height/width of the svg`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`// Make X grid:
svg
  .append("g")
  .attr("class", "chartGrid")
  .attr("transform", \`translate(0, \${height})\`)
  .call(
    makeXGridlines(xScale)
      // STRETCH IT PARALLEL TO Y:
      .tickSize(-height)
      // NO FORMAT, THESE JUST BE LINES:
      .tickFormat("")
  )

// Make Y grid:
svg
  .append("g")
  .attr("class", "chartGrid")
  .call(
    makeYGridlines(yScale)
      // STRETCH IT PARALLEL TO X:
      .tickSize(-width)
      // NO FORMAT, THESE JUST BE LINES:
      .tickFormat("")
  )
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`You might be noticing a pattern: When we want to add a new feature or visualization,`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote"><MDXTag name="em" components={components} parentName="p">{`We start by 1.) appending to our SVG element (or “selecting” an existing one), then 2.) configure the appended/selected element by chaining the methods calls we need, 3.) occasionally using `}<MDXTag name="inlineCode" components={components} parentName="em">{`.call`}</MDXTag>{` when we need to hook into a different context or functionality.`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Let’s see how our gridlines turned out:`}</MDXTag>
      <Chart targetNode={"#chart4"} data={data.fakeData4} transform="translate(47.5, 5)" ticks={true} gridlines={true} lineColor="63, 62, 62" background="rgb(253, 250, 243)" fillColor="15, 103, 36">
  <div id="chart4" />
      </Chart>
      <MDXTag name="p" components={components}>{`See? This stuff isn’t so bad. Think of all the stuff we can draw. What if we could consume an API to paint a picture of how little crypto is worth today, compared to its market cap 2 years ago?`}</MDXTag>
      <MDXTag name="p" components={components}>{`To do that, we would simply use a `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/d3/d3-scale#time-scales"
        }}>{`D3 time-scale`}</MDXTag>{` instead of a linear-scale. That’s outside the scope of this tutorial, but it isn’t hard to do once you understand `}<MDXTag name="inlineCode" components={components} parentName="p">{`scaleLinear`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Creating User Interaction`}</MDXTag>
      <MDXTag name="p" components={components}>{`This is where our app becomes dynamic.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Although the data for this blog is hardcoded, you could use the Google Maps Markers API to allow users to drag and drop pins on a map (see `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "/posts/2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react"
        }}>{`Part I for more info on maps and markers`}</MDXTag>{`).`}</MDXTag>
      <MDXTag name="p" components={components}>{`D3 gives you some callback functions that give you control over when and how your visualizations should rerender. Let’s see how that works.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`1. Create an Infobox & Crossbar on Hover`}</MDXTag>
      <MDXTag name="p" components={components}>{`When a user hovers over our chart, they should be able to see specific data for that particular intersection.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Changes along the x-axis will draw a vertical line (which I’m calling a `}<MDXTag name="inlineCode" components={components} parentName="p">{`crossBar`}</MDXTag>{`) at that point, and next to it an `}<MDXTag name="inlineCode" components={components} parentName="p">{`infobox`}</MDXTag>{` should read back the specific x- and y- values at the nearest point.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We’re going to use D3 to create them; note that their display is initialized to `}<MDXTag name="inlineCode" components={components} parentName="p">{`none`}</MDXTag>{` because the user has not yet hovered:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`const crossBar = svg
  .append("g")
  .attr("class", "crossBar")
  .style("display", "none")

crossBar
  .append("line")
  .attr("x1", 0)
  .attr("x2", 0)
  .attr("y1", height)
  .attr("y2", 0)

crossBar
  .append("text")
  .attr("x", 10)
  .attr("y", 17.5)
  .attr("class", "crossBarText")

const infoBox = svg
  .append("g")
  .attr("class", "infoBox")
  .style("display", "none")

infoBox
  .append("rect")
  .attr("x", 0)
  .attr("y", 10)
  .style("height", 45)
  .style("width", 125)

const infoBoxElevation = infoBox
  .append("text")
  .attr("x", 8)
  .attr("y", 30)
  .attr("class", "infoBoxElevation")

infoBoxElevation
  .append("tspan")
  .attr("class", "infoBoxElevationTitle")
  .text("Elev: ")

infoBoxElevation.append("tspan").attr("class", "infoBoxElevationValue")
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`These are both just SVG elements that we’re creating, so we have access to all the same methods and utilities that we used for our chart.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Next we need to create a `}<MDXTag name="inlineCode" components={components} parentName="p">{`rect`}</MDXTag>{` element that will act as an overlay for our entire chart. The overlay will have a higher z-index than the chart so that it takes precedence when receiving mouseover events.`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`svg
  .append("rect")
  .attr("class", "chartOverlay")
  .attr("width", width)
  .attr("height", height)
  .on("mouseover", function() {
    crossBar.style("display", null)
    infoBox.style("display", null)
  })
  .on("mouseout", function() {
    crossBar.style("display", "none")
    infoBox.style("display", "none")
  })
  .on("mousemove", mousemove)

function mousemove() {
  return null
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Did you see that? I snuck in the event handlers. A dozen lines of code is all it took!`}</MDXTag>
      <MDXTag name="p" components={components}>{`D3 makes it easy to manage events with its `}<MDXTag name="inlineCode" components={components} parentName="p">{`on`}</MDXTag>{` method, which might remind you of jQuery.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We create our overlay, giving it the same width and height as our chart, along with a className so we give it absolute positioning and a z-index higher than the chart.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Then we define 3 eventhandlers: one when a user mouses over our overlay, one when a user mouses out, and one that listens for changes in the user’s screenX and screenY coordinates.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you’re familiar with eventhandlers then you probably already see what this code is doing. Since we stored references to our crossBar and infoBox earlier, we can mutate their display to appear or disapear when the user enters or exits the chart with her mouse.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We will implement `}<MDXTag name="inlineCode" components={components} parentName="p">{`mousemove`}</MDXTag>{` next, as it requires a little extra work. For now it’s just a noop to satisfy the compiler.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s fire things up and see if our eventhandlers do what we want:`}</MDXTag>
      <Chart targetNode={"#chart5"} data={data.fakeData5} transform="translate(47.5, 5)" ticks={true} gridlines={true} lineColor="127, 54, 61" fillColor="250, 104, 118" background="rgba(255,163,85,0.15)">
  <div id="chart5" />
      </Chart>
      <MDXTag name="p" components={components}>{`This is getting fun. Now the user can hover over the area chart and get a localized display of the elevation and distance at that point in space.`}</MDXTag>
      <MDXTag name="p" components={components}>{`There were a couple other things I had to implement to get this to work, namely the `}<MDXTag name="inlineCode" components={components} parentName="p">{`mousemove`}</MDXTag>{` function.`}</MDXTag>
      <MDXTag name="p" components={components}>{`After we get through this part, the last thing to do is wire this chart up to a map so they can start working together.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`D3 Event Callbacks & Bisection`}</MDXTag>
      <MDXTag name="p" components={components}>{`Bisection. It just sounds hard. To be honest I didn’t fully understand why this was the right solution until after I saw it in action (and even then it still took some time to sink in).`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s eat the frog and learn about bisecting data.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`1. Bisecting`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`const bisect = d3.bisector(function(d) {
  return d.x
}).left
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`[WIP]`}</MDXTag>
      <MDXTag name="h3" components={components}>{`2. D3 Callbacks`}</MDXTag>
      <MDXTag name="p" components={components}>{`Phew, now that that’s over, callbacks are going to be easy. Let’s dive right in.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Check out what the `}<MDXTag name="inlineCode" components={components} parentName="p">{`mouseover`}</MDXTag>{` function is doing.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Note`}</MDXTag>{` that it needs to be a function declaration or a function expression so we keep `}<MDXTag name="inlineCode" components={components} parentName="p">{`this`}</MDXTag>{` context around — `}<MDXTag name="em" components={components} parentName="p">{`an arrow function won’t work`}</MDXTag>{` unless you keep a reference to this around (remember, these aren’t React classes, so in my opinion `}<MDXTag name="inlineCode" components={components} parentName="p">{`apply`}</MDXTag>{` would be my preference over `}<MDXTag name="inlineCode" components={components} parentName="p">{`bind`}</MDXTag>{`).`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`// NEEDS TO BE A FUNCTION EXPRESSION FOR ACCESS TO \`THIS\`!
function mousemove() {
  const x0 = xScale.invert(d3.mouse(this)[0])
  const i = bisect(data, x0, 1)
  const d0 = data[i - 1]
  const d1 = data[i]
  const d = !d1 ? d0 : x0 - d0.x > d1.x - x0 ? d1 : d0
  crossBar.attr("transform", \`translate(\${xScale(d.x)}, 0)\`)
  crossBar.select("text").text(d3.format(".1f")(metersToMiles(d.x)) + " mi")
  infoBox.attr("transform", \`translate(\${xScale(d.x) + 10}, 12.5)\`)
  infoBox
    .select(".infoBoxElevationValue")
    .text(d3.format(",.0f")(metersToFeet(d.y)) + " ft")
  infoBox.select(".infoBoxGradeValue").text(d3.format(".1%")(d.grade))
  return null
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`That’s it. There’s a lot going on in there, and we’ll unpack it, but that’s all the set up for our elevation chart.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We already got our maps set up last time, but if you aren’t familiar with the Google Maps API I recommend `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "/posts/2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react"
        }}>{`reading this first`}</MDXTag>{`, as it goes over the exact requirements that we need to finish up this project.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Almost done. We’ll put the finishing touches on our feature in Part 3.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Until next time!`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Note:`}</MDXTag>{` This is part 2 of a 3-part series about using Google Maps and D3 to create an elevation graph that responds dynamically with user interaction. Here are all 3 posts:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 1: Google Maps`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-16-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 2: D3 for Data Visualization`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-03-27-draw-an-interactive-elevation-chart-with-d3-and-react-pt-3"
          }}>{`Part 3: Creating User Interaction`}</MDXTag></MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "path": "/2019-02-16-draw-an-interactive-elevation-chart-with-d3-and-react-pt-2",
  "date": "2019-02-16",
  "title": "Draw an Interactive Elevation Chart with D3 & React, Part 2",
  "image": "2019-02-16-draw-an-interactive-elevation-chart-with-d3-and-react-pt-2.gif",
  "sharpImage": false,
  "imgOverlay": null,
  "tags": ["react", "d3", "javascript", "google maps", "data-visualization"],
  "excerpt": "Part 2: Data visualization is too fun. My team made an app that consumes a user’s trip data and uses it to draw cool stuff. Here’s how we used React, D3 and Google Maps to do it.",
  "published": true
};
      